import React, { useState, useEffect } from "react";
import OpsBreadcrumb from "../../../components/NewBreadcrumbs";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import PageHeader from "../../../components/PageHeader";
import {
  getVehiclesDiscovery,
  getVehiclesDiscoveryImprovementMetrics,
} from "../vehicleDiscoveryServices";
import DepotSessions from "./table";
import ComponentLoader from "../../../components/ComponentLoader";
import DebuggingCheckList from "./debugging-checklist";
import DepotVehicleDiscoveryPieChartInsights from "./pie-chart";
import "../vehicleDiscovery.scss";
import { Grid, Tooltip, Divider } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import ContentCard from "../../../components/ContentCard";
import MiniChart from "./mini-chart";
import ToastMessage from "../../../components/ToastMessage";
import format from "date-fns/format";

const AllBreadcrumbsLinks = [
  {
    link: "/vehicles",
    title: "Vehicle Discovery",
  },
];

export default function DepotDetailsPage() {
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const depotId = searchParams.get("depotId");
  const depotName = searchParams.get("depotName");
  const fromDate = searchParams.get("fromDate");
  const toDate = searchParams.get("toDate");
  const payload = {
    depotId: [depotId],
    fromDate: fromDate,
    toDate: toDate,
  };

  const [isLoading, setLoading] = React.useState(false);
  const [toast, setToast] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [vehicleDiscoveryData, setVehicleDiscoveryData] = useState([]);
  const [vehicleDiscoveryMetrics, setVehicleDiscoveryMetrics] = useState({
    depotSessionMetrics: [],
    makeModelSessionMetrics: [],
    changeSuccess: "-",
  });
  const [loadingImprovementMetrics, setLoadingImprovementMetrics] =
    useState(false);
  const [
    vehicleDiscoveryImprovementMetrics,
    setVehicleDiscoveryImprovementMetrics,
  ] = useState({
    numberOfChargers: 0,
    numberOfChargersWithValidLocation: 0,
    numberOfRegisteredVIN: 0,
    numberOfRegisteredVINWithVIDMapping: 0,
  });

  const apiCallForImprovementMetrics = async () => {
    setLoadingImprovementMetrics(true);
    const res = await getVehiclesDiscoveryImprovementMetrics(payload);
    setLoadingImprovementMetrics(false);

    if (res.statusCode === 200) {
      setVehicleDiscoveryImprovementMetrics(res.data);
    } else {
      setToast({
        isOpen: true,
        message: "Internal Service Failure",
        type: "error",
      });
    }
  };

  const apiCall = async () => {
    setLoading(true);
    const res = await getVehiclesDiscovery(payload);
    setLoading(false);

    if (res.statusCode === 200) {
      const { ["allSessionData"]: allSessionData, ...metrics } = res.data;
      setVehicleDiscoveryData(allSessionData);
      setVehicleDiscoveryMetrics(metrics);
      duplicateApiCall(metrics);
    } else {
      setToast({
        isOpen: true,
        message: "Internal Service Failure",
        type: "error",
      });
    }
  };

  const duplicateApiCall = async (metrics) => {
    const lastFromDate = new Date(fromDate);
    const lastToDate = new Date(toDate);
    lastFromDate.setMonth(lastFromDate.getMonth() - 1);
    lastToDate.setMonth(lastToDate.getMonth() - 1);
    const lFromDate = new Date(lastFromDate);
    const lTDate = new Date(lastToDate);
    const payload1 = {
      depotId: [depotId],
      fromDate: format(new Date(lFromDate), "yyyy-MM-dd"),
      toDate: format(new Date(lTDate), "yyyy-MM-dd"),
    };
    const duplicateres = getVehiclesDiscovery(payload1);
    duplicateres.then((res) => {
      if (res.statusCode === 200) {
        const data = {
          ...metrics,
          changeSuccess: metrics?.depotSessionMetrics?.map((ele) => {
            let depots = res.data?.depotSessionMetrics.find(
              (ele1) => ele.depotId == ele1.depotId
            );
            let change = 0;
            if (
              depots &&
              depots?.totalNumberOfSuccesfullDiscoverySessions &&
              depots.totalSessions &&
              ele?.totalNumberOfSuccesfullDiscoverySessions &&
              ele.totalSessions
            ) {
              change =
                Math.round(
                  Number(
                    (ele?.totalNumberOfSuccesfullDiscoverySessions /
                      ele?.totalSessions) *
                      100
                  ),
                  2
                ) -
                Math.round(
                  Number(
                    (depots?.totalNumberOfSuccesfullDiscoverySessions /
                      depots.totalSessions) *
                      100
                  ),
                  2
                );
            }
            depots = depots ? change : "-";
            return depots;
          }),
        };

        setVehicleDiscoveryMetrics(data);
      } else {
        setToast({
          isOpen: true,
          message: "Internal Service Failure",
          type: "error",
        });
      }
    });
  };

  useEffect(() => {
    Promise.allSettled([apiCall(), apiCallForImprovementMetrics()]);
  }, []);

  return (
    <>
      <OpsBreadcrumb
        AllBreadcrumbsLinks={AllBreadcrumbsLinks}
        title={depotName}
      />
      <PageHeader title={depotName} />
      <ContentCard>
        <Grid container alignItems={"center"} spacing={1} m={0}>
          <ComponentLoader isLoading={isLoading}>
            <Grid item xs>
              <div>
                <div className="title_for_chargersCount">
                  <p className="metrics_titles">
                    Average success %
                    <Tooltip title="It shows the overall average success of vehicle discovery for a specific depot in the given time range">
                      <span className="info">
                        <InfoIcon fontSize="small" className="infoIconColor" />
                      </span>
                    </Tooltip>
                  </p>
                  <div className="chartPosition">
                    <MiniChart chartType={"up"} />
                  </div>
                </div>
                <div className="fault_num_and_select">
                  <p id="chargers_count">
                    {
                      vehicleDiscoveryMetrics?.averageMetrics
                        ?.averageVehicleDiscoveryPercentage
                    }
                    %
                  </p>
                </div>
              </div>
            </Grid>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Grid item xs>
              <div>
                <div className="title_for_chargersCount">
                  <p className="metrics_titles">
                    Change success%
                    <Tooltip title="It show the change in success% compared to the last month for a specific depot">
                      <span className="info">
                        <InfoIcon fontSize="small" className="infoIconColor" />
                      </span>
                    </Tooltip>
                  </p>

                  <div className="chartPosition">
                    {vehicleDiscoveryMetrics?.changeSuccess != "-" ? (
                      <MiniChart
                        chartType={
                          vehicleDiscoveryMetrics?.changeSuccess >= 0
                            ? "up"
                            : "down"
                        }
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="fault_num_and_select">
                  <p id="chargers_count">
                    {vehicleDiscoveryMetrics?.changeSuccess}
                    {vehicleDiscoveryMetrics?.changeSuccess != "-" ? "%" : ""}
                  </p>
                </div>
              </div>
            </Grid>
          </ComponentLoader>
        </Grid>
      </ContentCard>
      <DebuggingCheckList />
      <Grid
        container
        flexDirection={"row"}
        rowSpacing={0.5}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        <Grid item xs={6}>
          <ContentCard>
            <DepotVehicleDiscoveryPieChartInsights
              chartName={"vehicleInsights"}
              loadingImprovementMetrics={loadingImprovementMetrics}
              vehicleDiscoveryImprovementMetrics={
                vehicleDiscoveryImprovementMetrics
              }
            />
          </ContentCard>
        </Grid>
        <Grid item xs={6}>
          <ContentCard>
            <DepotVehicleDiscoveryPieChartInsights
              chartName={"chargerInsights"}
              loadingImprovementMetrics={loadingImprovementMetrics}
              vehicleDiscoveryImprovementMetrics={
                vehicleDiscoveryImprovementMetrics
              }
            />
          </ContentCard>
        </Grid>
      </Grid>
      <DepotSessions
        isLoading={isLoading}
        vehicleDiscoveryData={vehicleDiscoveryData}
      />
      <ToastMessage toast={toast} setToast={setToast} />
    </>
  );
}
